
        import { links } from "./src/main";
        window.addEventListener("load", () => {
            const linkContainer = document.getElementById('links');
            links.forEach(link => {
                const li = document.createElement("li");
                li.classList.add("playground-link");

                li.innerHTML = `<a href="${link.url}">${link.label}</a>`;

                linkContainer.append(li);
            });
        })
    